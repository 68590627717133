import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { wrapCreateBrowserRouter } from '@sentry/react';
import { localeLoader } from 'i18n/activateLocale';
import { ErrorPage } from 'pages/ErrorPage';
import { NotFound } from 'pages/NotFound';
import { Permission } from 'permission/Permission';
import {
    action,
    changePasswordAction,
    createUserAction,
    deleteAction,
    forgotPasswordAction,
    loginAction,
    resetPasswordAction,
} from 'store/action';
import { api } from 'store/api';
import { DEFAULT_PAGINATION_REQUEST } from 'store/config';
import { allLoader, detailLoader, optionsLoader, paginationLoader, userLoader } from 'store/loader';
import { store } from 'store/store';
import { ScheduleStepStatus } from 'types';
import { WithNavigation } from 'WithNavigation';
import { Root } from './Root';

const createRouter = wrapCreateBrowserRouter(createBrowserRouter) as typeof createBrowserRouter;

export const router = createRouter([
    {
        element: <Root />,
        loader: localeLoader,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'no-access',
                lazy: () => import('pages/Login/NoAccess'),
            },
            {
                path: 'logout',
                lazy: () => import('pages/Login/Logout'),
            },
            {
                path: 'login',
                lazy: () => import('pages/Login/LoginPage'),
                errorElement: <ErrorPage />,
                action: loginAction,
                children: [
                    {
                        index: true,
                        lazy: () => import('pages/Login/Login'),
                    },
                    {
                        path: 'forgot-password',
                        lazy: () => import('pages/Login/ForgotPassword'),
                        action: forgotPasswordAction,
                    },
                    {
                        path: 'reset-password',
                        lazy: () => import('pages/Login/ResetPassword'),
                        action: resetPasswordAction,
                    },
                ],
            },
            {
                path: 'signup',
                action: createUserAction,
                lazy: () => import('pages/Invite/SignupPage'),
            },
            {
                path: 'register',
                lazy: () => import('pages/Invite/InvitePage'),
                loader: async () => {
                    const promise = store.dispatch(api.endpoints.invite.initiate());

                    await promise.unwrap();
                    promise.unsubscribe();
                    return null;
                },
            },
            {
                element: <WithNavigation />,
                loader: userLoader,
                children: [
                    {
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                index: true,
                                lazy: () => import('pages/dashboard'),
                            },
                            {
                                path: 'buttons',
                                // Component: () => (
                                //     <Permission role="!FASTIS">
                                //         <Navigate to=".." />
                                //     </Permission>
                                // ),
                                lazy: () => import('pages/Buttons'),
                            },
                            {
                                path: 'icons',
                                lazy: () => import('pages/Icons/Icons'),
                            },
                            {
                                path: 'change-password',
                                lazy: () => import('pages/Login/ChangePassword'),
                                action: changePasswordAction,
                            },
                            {
                                path: 'profile',
                                lazy: () => import('pages/UserProfile'),
                                action: action('editUserProfile', true),
                            },
                            {
                                path: 'administrator',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/Users/UsersList'),
                                        loader: paginationLoader('listAdministrators'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/Users/UserCreate'),
                                        action: action('createAdministrators'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteAdministrators'),
                                        children: [
                                            {
                                                index: true,
                                                Component: () => <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/Users/UserEdit'),
                                                loader: paginationLoader('getAdministrator'),
                                                action: action('editAdministrators'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'absences',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/Absences/AbsenceList'),
                                        loader: paginationLoader('listAbsences'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/Absences/AbsenceCreate'),
                                        action: action('createAbsence'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteAbsence'),
                                        children: [
                                            {
                                                index: true,
                                                Component: () => <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/Absences/AbsenceEdit'),
                                                loader: paginationLoader('getAbsence'),
                                                action: action('editAbsence'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'absence-types',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/AbsenceTypes/AbsenceTypeList'),
                                        loader: paginationLoader('listAbsenceTypes'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/AbsenceTypes/AbsenceTypeCreate'),
                                        action: action('createAbsenceType'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteAbsenceType'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/AbsenceTypes/AbsenceTypeEdit'),
                                                loader: paginationLoader('getAbsenceType'),
                                                action: action('editAbsenceType'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'absence-mutations',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/AbsenceMutations/AbsenceMutationList'),
                                        loader: paginationLoader('listAbsenceMutation'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/AbsenceMutations/AbsenceMutationCreate'),
                                        action: action('createAbsenceMutation'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteAbsenceMutation'),
                                        children: [
                                            {
                                                index: true,
                                                Component: () => <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/AbsenceMutations/AbsenceMutationEdit'),
                                                loader: paginationLoader('getAbsenceMutation'),
                                                action: action('editAbsenceMutation'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'financial',
                                element: <Navigate to="/invoices" />,
                            },
                            {
                                path: 'invoices',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/Invoice/InvoiceList'),
                                        loader: paginationLoader('listInvoice'),
                                    },

                                    {
                                        path: 'new',
                                        lazy: () => import('pages/Invoice/InvoiceCreate'),
                                        action: action('createInvoice'),
                                    },

                                    {
                                        path: ':id',
                                        action: deleteAction('deleteInvoice'),
                                        children: [
                                            {
                                                index: true,
                                                Component: () => <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/Invoice/InvoiceEdit'),
                                                action: action('editInvoice'),
                                                loader: async (arg) => {
                                                    await paginationLoader('getInvoice')(arg);
                                                    return null;
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'invoice-collections',
                                children: [
                                    {
                                        index: true,
                                        loader: paginationLoader('listInvoiceCollection'),
                                        lazy: () => import('pages/InvoiceCollection/InvoiceCollectionList'),
                                    },
                                    {
                                        loader: paginationLoader('listInvoiceCollection'),
                                        lazy: () => import('pages/InvoiceCollection/InvoiceCollectionList'),
                                        children: [
                                            {
                                                path: 'new',
                                                lazy: () => import('pages/InvoiceCollection/InvoiceCollectionCreate'),
                                                action: action('createInvoiceCollection'),
                                            },
                                        ],
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteInvoiceCollection'),
                                        children: [
                                            {
                                                index: true,
                                                lazy: () => import('pages/InvoiceCollection/InvoiceCollectionDetail'),
                                                loader: async (arg) => {
                                                    await detailLoader('getInvoiceCollection')(arg);
                                                    return null;
                                                },
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/InvoiceCollection/InvoiceCollectionEdit'),
                                                action: action('editInvoiceCollection'),
                                                loader: async (arg) => {
                                                    await detailLoader('getInvoiceCollection')(arg);
                                                    return null;
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'schedule',
                                children: [
                                    {
                                        path: '*',
                                        lazy: () => import('pages/calendar/CalendarPage'),
                                        loader: async () => {
                                            await Promise.all([
                                                optionsLoader('getSkills'),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getTimeslots'),
                                                optionsLoader('getLocations'),
                                            ]);
                                            return null;
                                        },
                                        children: [
                                            {
                                                path: 'grid/day',
                                                lazy: () => import('pages/calendar/CalendarGridDay'),
                                                loader: async (args) => {
                                                    await allLoader('getShifts')(args);

                                                    return null;
                                                },
                                            },
                                            {
                                                path: 'grid/week',
                                                lazy: () => import('pages/calendar/CalendarGridWeek'),
                                            },
                                            {
                                                path: 'grid/month',
                                                lazy: () => import('pages/calendar/CalendarGridMonth'),
                                                loader: allLoader('getCalendarHeatmap'),
                                            },
                                            {
                                                path: 'grid/year',
                                                lazy: () => import('pages/calendar/CalendarGridYear'),
                                                loader: allLoader('getCalendarHeatmap'),
                                            },
                                            {
                                                path: 'list/:timeSpan',
                                                lazy: () => import('pages/calendar/CalendarList'),
                                                loader: allLoader('getShifts'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'preferences',
                                lazy: () => import('pages/Preferences'),
                            },
                            {
                                path: 'marketplace',
                                lazy: () => import('pages/shifts/MarketplaceList'),
                                loader: async (args) => {
                                    await Promise.all([
                                        paginationLoader('listMarketplace')(args),
                                        optionsLoader('getSkills'),
                                        optionsLoader('getProfessions'),
                                        optionsLoader('getTimeslots'),
                                        optionsLoader('getLocations'),
                                    ]);
                                    return null;
                                },
                            },
                            {
                                path: 'shifts',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/shifts/ShiftList'),
                                        loader: async (args) => {
                                            await Promise.all([
                                                paginationLoader('listShifts')(args),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getTimeslots'),
                                                optionsLoader('getLocations'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/shifts/ShiftCreate'),
                                        action: action('createShift'),
                                        loader: async () => {
                                            await Promise.all([
                                                optionsLoader('getLocations'),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getTemplates'),
                                                optionsLoader('getTimeslots'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteShift'),
                                        children: [
                                            {
                                                lazy: () => import('pages/shifts/ShiftDetail'),
                                                loader: async (arg) => {
                                                    const promise = store.dispatch(
                                                        api.endpoints.getMarketplaceOptions.initiate({
                                                            search: {
                                                                'filter[shift_id]': arg.params.id as string,
                                                            } as Record<string, string>,
                                                        }),
                                                    );

                                                    await Promise.all([
                                                        detailLoader('getShiftWithRelationship')(arg),
                                                        promise.unwrap(),
                                                    ]);

                                                    return null;
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: null,
                                                    },
                                                    {
                                                        path: 'marketplace/new',
                                                        lazy: () =>
                                                            import('pages/Declarations/_DeclarationCreateModal'),
                                                        action: action('createDeclaration', true),
                                                    },
                                                    {
                                                        path: 'marketplace/offer/assign',
                                                        lazy: () => import('pages/shifts/_ShiftMarketplaceAssignModal'),
                                                        action: action('editAssignMarketplaceOffer', true),
                                                        loader: async ({ params: { id: shiftId } }) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getShiftWithRelationship.initiate({
                                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                    id: shiftId!,
                                                                }),
                                                            );

                                                            const shift = await promise.unwrap();

                                                            const profile = store.dispatch(
                                                                api.endpoints.getProfiles.initiate({
                                                                    'filter[on_tenant]': shift.tenant_id,
                                                                    'fields[profiles]': 'name',
                                                                }),
                                                            );

                                                            await profile.unwrap();
                                                            profile.unsubscribe();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                    {
                                                        path: 'marketplace/offer/swap',
                                                        lazy: () => import('pages/shifts/_ShiftMarketplaceSwapModal'),
                                                        action: action('createMarketplaceOptions', true),
                                                        loader: async ({ params: { id: shiftId } }) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getShiftWithRelationship.initiate({
                                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                    id: shiftId!,
                                                                }),
                                                            );

                                                            const shift = await promise.unwrap();

                                                            const profile = store.dispatch(
                                                                api.endpoints.getProfiles.initiate({
                                                                    'filter[on_tenant]': shift.tenant_id,
                                                                    'fields[profiles]': 'name',
                                                                }),
                                                            );

                                                            await profile.unwrap();
                                                            profile.unsubscribe();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                    {
                                                        path: 'marketplace/offer/trade',
                                                        lazy: () => import('pages/shifts/_ShiftMarketplaceTradeModal'),
                                                        action: action('createMarketplaceOptions', true),
                                                        loader: ({ params: { id: shiftId } }) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getShift.initiate({
                                                                    path: {
                                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                        id: shiftId!,
                                                                    },
                                                                }),
                                                            );

                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },

                                                    {
                                                        path: 'new/declaration',
                                                        lazy: () =>
                                                            import('pages/Declarations/_DeclarationCreateModal'),
                                                        action: action('createDeclaration', true),
                                                    },
                                                    {
                                                        path: ':declarationId',
                                                        lazy: () => import('pages/Declarations/_DeclarationEditModal'),
                                                        action: action('editDeclaration', true),
                                                        loader: async ({ params: { declarationId } }) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getDeclaration.initiate({
                                                                    path: { id: declarationId! },
                                                                }),
                                                            );

                                                            await promise.unwrap();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/shifts/ShiftEdit'),
                                                action: action('editShift'),
                                                loader: async (arg) => {
                                                    await Promise.all([
                                                        paginationLoader('getShift')(arg),
                                                        optionsLoader('getLocations'),
                                                        optionsLoader('getProfessions'),
                                                        optionsLoader('getTimeslots'),
                                                        optionsLoader('getTemplates'),
                                                        optionsLoader('getSkills'),
                                                    ]);
                                                    return null;
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'locations',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/locations/LocationList'),
                                        loader: paginationLoader('listLocations'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/locations/LocationCreate'),
                                        action: action('createLocation'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteLocation'),
                                        children: [
                                            {
                                                index: true,
                                                lazy: () => import('pages/locations/LocationDetail'),
                                                loader: paginationLoader('getLocation'),
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/locations/LocationEdit'),
                                                loader: paginationLoader('getLocation'),
                                                action: action('editLocation'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'pricings',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/Pricing/PricingList'),
                                        loader: paginationLoader('listPricings'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deletePricing'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/Pricing/PricingEdit'),
                                                loader: paginationLoader('getPricing'),
                                                action: action('editPricing'),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/Pricing/pricingCreate'),
                                        action: action('createPricing'),
                                        loader: async () => {
                                            await Promise.all([
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getLocations'),
                                            ]);
                                            return null;
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'professions',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/professions/ProfessionList'),
                                        loader: paginationLoader('listProfessions'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/professions/ProfessionCreate'),
                                        action: action('createProfession'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteProfession'),
                                        children: [
                                            {
                                                index: true,
                                                // lazy: () => import('pages/professions/ProfessionDetail'),
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/professions/ProfessionEdit'),
                                                loader: paginationLoader('getProfession'),
                                                action: action('editProfession'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'profiles',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/profiles/ProfileList'),
                                        loader: async (args) => {
                                            await Promise.all([
                                                paginationLoader('listProfiles')(args),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getTimeslots'),
                                                optionsLoader('getLocations'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/profiles/ProfileCreate'),
                                        action: action('createProfile'),
                                        loader: async () => {
                                            await Promise.all([
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getTimeslots'),
                                                optionsLoader('getLocations'),
                                                optionsLoader('getPricings'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteProfile'),
                                        children: [
                                            {
                                                index: true,
                                                lazy: () => import('pages/profiles/ProfileDetail'),
                                                loader: async (args) => {
                                                    await Promise.all([
                                                        paginationLoader('getProfile')(args),
                                                        optionsLoader('getProfessions'),
                                                        optionsLoader('getSkills'),
                                                        optionsLoader('getTimeslots'),
                                                        optionsLoader('getLocations'),
                                                        optionsLoader('getGroupProfiles'),
                                                        optionsLoader('getUploadTypes'),
                                                    ]);
                                                    return null;
                                                },
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/profiles/ProfileEdit'),
                                                action: action('editProfile'),
                                                loader: async (args) => {
                                                    await Promise.all([
                                                        paginationLoader('getProfile')(args),
                                                        optionsLoader('getProfessions'),
                                                        optionsLoader('getSkills'),
                                                        optionsLoader('getTimeslots'),
                                                        optionsLoader('getLocations'),
                                                        optionsLoader('getPricings'),
                                                        optionsLoader('getUploadTypes'),
                                                    ]);
                                                    return null;
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'scheduling',
                                children: [
                                    {
                                        index: true,
                                        loader: async (args) => {
                                            const filters = { 'filter[status]': ScheduleStepStatus.ACTIVE };
                                            await paginationLoader('listScheduleSteps', filters)(args);

                                            return null;
                                        },
                                        lazy: () => import('pages/schedules/Association/ScheduleList'),
                                    },
                                    {
                                        path: 'step/:id',
                                        action: action('editScheduleSlotToggle', true, false),
                                        loader: async (args) => {
                                            const scheduleStepPromise = store.dispatch(
                                                api.endpoints.getScheduleStep.initiate({
                                                    path: {
                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                        id: args.params.id!,
                                                    },
                                                }),
                                            );
                                            const schedule_step = await scheduleStepPromise.unwrap();

                                            const searchArgs = {
                                                search: {
                                                    'filter[compatible_with_step]': [
                                                        schedule_step.tenant_id,
                                                        schedule_step.id,
                                                    ].join('_'),
                                                    ...DEFAULT_PAGINATION_REQUEST,
                                                    sort: 'sorting',
                                                    include: 'current_schedule_options',
                                                },
                                            };

                                            await Promise.all([
                                                optionsLoader('getProfiles'),
                                                detailLoader('getScheduleStep')(args),
                                                paginationLoader('listScheduleShifts', searchArgs)(args),
                                            ]);

                                            scheduleStepPromise.unsubscribe();
                                            return null;
                                        },
                                        lazy: () => import('pages/schedules/Association/ScheduleDetail'),
                                    },
                                ],
                            },
                            {
                                path: 'planning',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/schedules/ScheduleList'),
                                        loader: paginationLoader('listSchedules'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/schedules/ScheduleCreate'),
                                        action: action('createSchedule'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteSchedule'),
                                        children: [
                                            {
                                                path: 'shifts',
                                                action: action('editScheduleSlotToggle', true, false),
                                                loader: async (args) => {
                                                    const promise = store.dispatch(
                                                        api.endpoints.getSchedule.initiate({
                                                            path: {
                                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                id: args.params.id!,
                                                            },
                                                        }),
                                                    );

                                                    const schedule = await promise.unwrap();

                                                    const conceptArgs = {
                                                        search: {
                                                            'filter[schedule_id]': schedule.id,
                                                        },
                                                    };

                                                    await Promise.all([
                                                        detailLoader('getSchedule')(args),
                                                        paginationLoader(
                                                            'listScheduleConceptShifts',
                                                            conceptArgs,
                                                        )(args),
                                                    ]);

                                                    promise.unsubscribe();
                                                    return null;
                                                },
                                                lazy: () => import('pages/schedules/ConceptSchedule'),
                                            },
                                            {
                                                lazy: () => import('pages/schedules/ScheduleDetail'),
                                                loader: async (args) => {
                                                    const promise = store.dispatch(
                                                        api.endpoints.listScheduleSteps.initiate({
                                                            search: {
                                                                'filter[schedule_id]': args.params.id!,
                                                                'page[number]': '1',
                                                                sort: 'created_at',
                                                            },
                                                        }),
                                                    );

                                                    await Promise.all([
                                                        paginationLoader('getSchedule')(args),
                                                        promise.unwrap(),
                                                    ]);

                                                    promise.unsubscribe();
                                                    return null;
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        // page does exist, in the layout. do not remove
                                                        // children are modals
                                                        element: null,
                                                    },

                                                    {
                                                        path: 'new',
                                                        lazy: () => import('pages/schedules/steps/NewScheduleStep'),
                                                        action: action('createScheduleStep', true),
                                                    },
                                                    {
                                                        path: ':stepId',
                                                        lazy: () => import('pages/schedules/steps/EditScheduleStep'),
                                                        action: action('editScheduleStep', true),
                                                        loader: async ({ params: { stepId } }) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getScheduleStep.initiate({
                                                                    path: { id: stepId! },
                                                                }),
                                                            );
                                                            await promise.unwrap();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'results/:stepId/:resultId',
                                                lazy: () => import('pages/schedules/results/ScheduleResults'),
                                                children: [
                                                    {
                                                        index: true,
                                                        lazy: () => import('pages/schedules/results/SupplyAndDemand'),
                                                        loader: async (args) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getScheduleResultSupplyAndDemand.initiate(
                                                                    args.params.resultId!,
                                                                ),
                                                            );
                                                            await promise.unwrap();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                    {
                                                        path: 'profile',
                                                        lazy: () => import('pages/schedules/results/SDPerProfile'),
                                                        loader: async (args) => {
                                                            const promise = store.dispatch(
                                                                api.endpoints.getScheduleResultSDProfile.initiate(
                                                                    args.params.resultId!,
                                                                ),
                                                            );
                                                            await promise.unwrap();
                                                            promise.unsubscribe();
                                                            return null;
                                                        },
                                                    },
                                                    {
                                                        path: 'shifts',
                                                        lazy: () => import('pages/schedules/results/Shifts'),
                                                    },
                                                    {
                                                        path: 'assignees',
                                                        lazy: () => import('pages/schedules/results/Assignees'),
                                                    },
                                                ],
                                            },

                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/schedules/ScheduleEdit'),
                                                loader: paginationLoader('getSchedule'),
                                                action: action('editSchedule'),
                                            },
                                            {
                                                path: 'plan',
                                                lazy: () => import('pages/schedules/plan/SchedulePlan'),
                                                loader: async (args) => {
                                                    await paginationLoader('getSchedule')(args);
                                                    return null;
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        Component: () => null,
                                                    },
                                                    {
                                                        path: 'new/:dateString',
                                                        lazy: () => import('pages/schedules/plan/NewScheduleSlot'),
                                                        action: action('createScheduleSlot', true),
                                                        loader: async (args) => {
                                                            await paginationLoader('getSchedule')(args);
                                                            return null;
                                                        },
                                                    },
                                                    {
                                                        path: 'new',
                                                        Component: () => <Navigate to=".." />,
                                                    },
                                                    {
                                                        path: ':slotId',
                                                        lazy: () => import('pages/schedules/plan/EditScheduleSlot'),
                                                        action: action('editScheduleSlot', true),
                                                        loader: async (args) => {
                                                            await store.dispatch(
                                                                api.endpoints.getScheduleSlot.initiate({
                                                                    path: { id: args.params.slotId! },
                                                                }),
                                                            );
                                                            await paginationLoader('getSchedule')(args);

                                                            return null;
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'tenant-settings',
                                element: (
                                    <Permission
                                        role="!PROFILE"
                                        ability="tenant.update"
                                        pass={<Outlet />}
                                        fail={<Navigate to="/" />}
                                    />
                                ),
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/Settings/SettingsPage'),
                                    },
                                    {
                                        path: ':id',
                                        loader: detailLoader('getTenantInfo'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'info',
                                                action: action('editTenantInfo', true),
                                                lazy: () => import('pages/Tenant/TenantEdit'),
                                            },
                                            {
                                                path: 'general',
                                                action: action('editTenantInfo', true),
                                                lazy: () => import('pages/Tenant/TenantGeneral'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'upload-types',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/UploadTypes/UploadTypeList'),
                                        loader: paginationLoader('listUploadTypes'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/UploadTypes/UploadTypesCreate'),
                                        action: action('createUploadType'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteUploadType'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/UploadTypes/UploadTypeEdit'),
                                                loader: paginationLoader('getUploadType'),
                                                action: action('editUploadType'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'skills',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/skills/SkillList'),
                                        loader: paginationLoader('listSkills'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/skills/SkillCreate'),
                                        action: action('createSkill'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteSkill'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/skills/SkillEdit'),
                                                loader: paginationLoader('getSkill'),
                                                action: action('editSkill'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'templates',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/templates/TemplateList'),
                                        loader: async (args) => {
                                            await Promise.all([
                                                paginationLoader('listTemplates')(args),
                                                optionsLoader('getSkills'),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getTimeslots'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/templates/TemplateCreate'),
                                        action: action('createTemplate'),
                                        loader: async () => {
                                            await Promise.all([
                                                optionsLoader('getSkills'),
                                                optionsLoader('getProfessions'),
                                                optionsLoader('getTimeslots'),
                                            ]);
                                            return null;
                                        },
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteTemplate'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/templates/TemplateEdit'),
                                                loader: async (arg) => {
                                                    await Promise.all([
                                                        paginationLoader('getTemplate')(arg),
                                                        optionsLoader('getSkills'),
                                                        optionsLoader('getProfessions'),
                                                        optionsLoader('getTimeslots'),
                                                    ]);
                                                    return null;
                                                },
                                                action: action('editTemplate'),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'timeslots',
                                children: [
                                    {
                                        index: true,
                                        lazy: () => import('pages/timeslots/TimeslotList'),
                                        loader: paginationLoader('listTimeslots'),
                                    },
                                    {
                                        path: 'new',
                                        lazy: () => import('pages/timeslots/TimeslotCreate'),
                                        action: action('createTimeslot'),
                                    },
                                    {
                                        path: ':id',
                                        action: deleteAction('deleteTimeslot'),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to=".." />,
                                            },
                                            {
                                                path: 'edit',
                                                lazy: () => import('pages/timeslots/TimeslotEdit'),
                                                loader: paginationLoader('getTimeslot'),
                                                action: action('editTimeslot'),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '*',
                        element: <NotFound />,
                    },
                ],
            },
        ],
    },
]);
