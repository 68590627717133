import { Trans } from '@lingui/macro';
import { m } from 'framer-motion';
import { useMemo } from 'react';
import { type PressEvent } from 'react-aria';
import { useNavigate } from 'react-router-dom';

import {
    dialogTriggerButton,
    navContent,
    navigation,
    navLogoWord,
    navMenu,
    navMenuList,
    navTrigger,
    textMuted,
    userCardInsetSubHeader,
} from './Navigation.css';

import { endOfMonth, endOfYear, getLocalTimeZone, startOfMonth, startOfYear, today } from '@internationalized/date';
import { Calendar } from 'assets/icons/Calendar';
import { MemoMoney } from 'assets/icons/MemoMoney';
import { MinusCircle } from 'assets/icons/MinusCircle';
import { Settings } from 'assets/icons/Settings';
import { UserCircle } from 'assets/icons/UserCircle';
import { Users } from 'assets/icons/Users';
import { FastisLogo, FastisWord } from 'assets/logos';
import { Button } from 'components/Button/Button';
import { stringifyAnyDate } from 'components/DatePicker/stringifyAnyDate';
import { Row } from 'components/Layouts/Row/Row';
import { Notifications } from 'components/Notifications/Notifications';
import { PageLoading } from 'components/Page/PageLoading';
import { Separator } from 'components/Separator/Separator';
import { Translate } from 'components/Translate/Translate';
import { useBoolean } from 'hooks/useBoolean';
import { useClickOutside } from 'hooks/useClickOutside';
import { useEventHandler } from 'hooks/useEventHandler';
import type { Layouts, TimeSpans } from 'pages/calendar/_types';
import { Permission } from 'permission/Permission';
import { useMe } from 'permission/useMe';
import { accessSlice } from 'store/accessSlice';
import { api } from 'store/api';
import { useAppDispatch } from 'store/store';
import { EntityType, type UUID } from 'types';
import { isDefined } from 'utils/isDefined';
import { stringifyRangeFilter } from 'utils/stringifyRangeFilter';
import { Menu } from './Menu';
import { NavCard } from './NavigationCard';
import { NavigationMenuCard } from './NavigationMenuCard';
import { NavMenuButton, NavMenuListLink } from './NavMenuListLink';

export const Navigation = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useBoolean(false);
    const buttonRef = useClickOutside<HTMLButtonElement>(setIsOpen.off, { active: isOpen });
    const listView: Layouts = 'list';
    const timeSpanMonthView: TimeSpans = 'month';
    const timeSpanYearView: TimeSpans = 'year';
    const now = today(getLocalTimeZone());
    const { user, activeAccess, accountsList, checkRole } = useMe();

    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    const drawerAnimation = {
        width: isOpen ? 330 : 0,
    };

    const handleNavigation = useEventHandler(() => {
        if (!isOpen) setIsOpen.on();
        if (isOpen) navigate('/');
    });

    const yearDateRange = useMemo(() => {
        return new URLSearchParams({
            'filter[shift_date]': stringifyRangeFilter({
                gte: stringifyAnyDate(startOfYear(now)),
                lte: stringifyAnyDate(endOfYear(now)),
            }),
        }).toString();
    }, [now]);

    const dateRange = useMemo(() => {
        return new URLSearchParams({
            'filter[shift_date]': stringifyRangeFilter({
                gte: stringifyAnyDate(startOfMonth(now)),
                lte: stringifyAnyDate(endOfMonth(now)),
            }),
        }).toString();
    }, [now]);

    const dispatch = useAppDispatch();

    const handleSelectProfile = useEventHandler((e: PressEvent) => {
        navigate('/');
        dispatch(accessSlice.actions.selectAccess(e.target.id as UUID));
        dispatch(api.util.invalidateTags(Object.values(EntityType)));
    });

    const scheduleLink = checkRole('PROFILE')
        ? `/schedule/${listView}/${timeSpanYearView}?${yearDateRange}&sort=sorting`
        : `/schedule/${listView}/${timeSpanMonthView}?${dateRange}&sort=sorting`;

    const navTriggerColor = isOpen ? 'textOnButton' : 'brand';
    const navTriggerVariant = isOpen ? 'nav' : 'primary';

    return (
        <header className={navigation}>
            <Row alignItems="center" height="full" width="full">
                <Button
                    onPress={handleNavigation}
                    color={navTriggerColor}
                    variant={navTriggerVariant}
                    shape="square"
                    size="small"
                    className={navTrigger({ isOpen })}
                    ref={buttonRef}
                >
                    <FastisLogo width={25} aria-hidden />
                    {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
                    {isOpen && <FastisWord aria-label="Fastis" className={navLogoWord} />}
                </Button>
                <m.div animate={drawerAnimation} className={navMenu}>
                    <div className={navContent}>
                        <Permission ability="shift.index">
                            <NavCard
                                header={<Trans id="Navigation.Schedule">Schedule</Trans>}
                                Icon={Calendar}
                                to={scheduleLink}
                                description={
                                    <Permission
                                        role="PROFILE"
                                        pass={
                                            <Trans id="Navigation.View_your_ongoing_schedule,_manage_your_shifts,_and_declare_overtime.">
                                                View your ongoing schedule, manage your shifts, and declare overtime.
                                            </Trans>
                                        }
                                        fail={
                                            <Trans id="Navigation.View_the_ongoing_schedule,_manage_shifts,_and_launch_new_schedules.">
                                                View the ongoing schedule, manage shifts, and launch new schedules.
                                            </Trans>
                                        }
                                    />
                                }
                            />
                        </Permission>

                        <Permission ability="shift.market">
                            <NavMenuListLink to="/marketplace">
                                <Trans id="Navigation.Marketplace">Marketplace</Trans>
                            </NavMenuListLink>
                        </Permission>

                        <Permission ability="schedule.index">
                            <NavMenuListLink to="/planning">
                                <Translate id="frontend.navigation.Schedules" />
                            </NavMenuListLink>
                        </Permission>

                        <NavMenuListLink to="/scheduling">
                            <Translate id="frontend.navigation.Scheduling" />
                        </NavMenuListLink>

                        <Permission role="ADMINISTRATOR_DEFAULT">
                            <Separator orientation="horizontal" color="panelSoft" />

                            <NavMenuListLink to="/shifts">
                                <Translate id="module.Shifts" />
                            </NavMenuListLink>
                        </Permission>

                        <Permission ability="shift.index">
                            <Separator orientation="horizontal" color="panelSoft" />
                        </Permission>

                        <Permission
                            role="PROFILE"
                            pass={
                                <NavMenuListLink to="/profiles">
                                    <Trans id="Navigation.My_profiles">My profiles</Trans>
                                </NavMenuListLink>
                            }
                            fail={
                                <Permission ability="profile.index">
                                    <NavCard
                                        header={<Trans id="Navigation.Profiles">Profiles</Trans>}
                                        Icon={Users}
                                        to="/profiles"
                                        description={
                                            <Trans id="Navigation.Manage_individuals,_businesses_and_groups_associated_with_your_schedules.">
                                                Manage individuals, businesses and groups associated with your
                                                schedules.
                                            </Trans>
                                        }
                                    />
                                </Permission>
                            }
                        />

                        <Permission ability="absence.index">
                            <NavMenuListLink to="/absences">
                                <Translate id="module.Absences" />
                            </NavMenuListLink>
                        </Permission>

                        <Permission ability="profile.index">
                            <Separator orientation="horizontal" color="panelSoft" />
                        </Permission>

                        <Permission ability="invoice.index">
                            <Permission role="!PROFILE">
                                <NavCard
                                    header={<Trans id="Navigation.Financial">Financial</Trans>}
                                    Icon={MemoMoney}
                                    to="/financial"
                                    description={
                                        <Trans id="Navigation.Manage_declarations_and_reports_associated_with_your_schedules.">
                                            Manage declarations and reports associated with your schedules.
                                        </Trans>
                                    }
                                />
                            </Permission>

                            <NavMenuListLink to="/invoices">
                                <Trans id="Navigation.Invoices">Invoices</Trans>
                            </NavMenuListLink>
                        </Permission>

                        <Permission ability="invoice-collection.index">
                            <NavMenuListLink to="/invoice-collections">
                                <Trans id="Navigation.Invoice_collections">Invoice collections</Trans>
                            </NavMenuListLink>
                        </Permission>

                        <Permission ability="invoice.index">
                            <Separator orientation="horizontal" color="panelSoft" />
                        </Permission>

                        <Permission role="!PROFILE">
                            <div className={navMenuList}>
                                <NavCard
                                    header={<Trans id="Navigation.Settings">Settings</Trans>}
                                    Icon={Settings}
                                    to="/tenant-settings"
                                    description={
                                        <Trans id="Navigation.Adjust_various_settings_and_entities_associated_with_your_schedules.">
                                            Adjust various settings and entities associated with your schedules.
                                        </Trans>
                                    }
                                />

                                <Permission ability="template.index">
                                    <NavMenuListLink to="/templates">
                                        <Translate id="module.Templates" />
                                    </NavMenuListLink>
                                </Permission>

                                <Permission ability="administrator.index">
                                    <NavMenuListLink to="/administrator">
                                        <Translate id="module.Administrators" />
                                    </NavMenuListLink>
                                </Permission>

                                <Permission ability="pricing.index">
                                    <NavMenuListLink to="/pricings">
                                        <Translate id="module.Pricings" />
                                    </NavMenuListLink>
                                </Permission>
                            </div>
                        </Permission>
                    </div>
                </m.div>
            </Row>
            <Row alignItems="center" height="full">
                {/* <Menu
                    scroll={false}
                    transformOrigin="top right"
                    content={
                        <Card shadow>
                            <CardInset color="accent" position="top">
                                <h3 className={userCardInsetSubHeader}>
                                    <Trans>Help center</Trans>
                                </h3>
                            </CardInset>
                            <h5>
                                <Trans>Contact our help desk</Trans>
                            </h5>
                        </Card>
                    }
                >
                    {(item) => (
                        <Button
                            {...item}
                            color="accent"
                            shape="square"
                            size="large"
                            variant="muted"
                            className={dialogTriggerButton}
                        >
                            <Information />
                        </Button>
                    )}
                </Menu> */}

                <Notifications />

                <Menu
                    scroll={false}
                    transformOrigin="top right"
                    content={
                        <NavigationMenuCard
                            position="top"
                            header={
                                <>
                                    <h6 className={userCardInsetSubHeader}>
                                        <Trans id="Navigation.Logged_in_as">Logged in as</Trans>
                                    </h6>

                                    {user.name}
                                </>
                            }
                        >
                            <NavMenuListLink color="brand" to="/preferences">
                                <Trans id="Navigation.Preferences">Preferences</Trans>
                            </NavMenuListLink>
                            <Permission ability="profile.update">
                                <NavMenuListLink color="brand" to="/profile">
                                    <Trans id="Navigation.Edit_information">Edit information</Trans>
                                </NavMenuListLink>
                            </Permission>
                            {isDefined(user.oauth_driver) ? null : (
                                <NavMenuListLink color="danger" to="/logout">
                                    <Trans id="Navigation.Log_out">Log out</Trans>
                                </NavMenuListLink>
                            )}

                            <Separator orientation="horizontal" />
                            {accountsList.length > 0 && (
                                <>
                                    <h6 className={textMuted}>
                                        <Trans id="Navigation.Switch_between_accounts">Switch between accounts</Trans>
                                    </h6>

                                    {accountsList.map((access) =>
                                        access.access_singular_tenant ? (
                                            <NavMenuButton
                                                key={access.id}
                                                id={access.id}
                                                color="brand"
                                                className={access.id === activeAccess.id ? 'active' : undefined}
                                                onPress={handleSelectProfile}
                                            >
                                                <UserCircle />
                                                {access.association_tenant_name}
                                            </NavMenuButton>
                                        ) : (
                                            <NavMenuButton
                                                key={access.id}
                                                id={access.id}
                                                color="brand"
                                                onPress={handleSelectProfile}
                                                className={access.id === activeAccess.id ? 'active' : undefined}
                                            >
                                                <MinusCircle />
                                                <Trans id="Navigation.No_account">No account</Trans>
                                            </NavMenuButton>
                                        ),
                                    )}
                                </>
                            )}
                        </NavigationMenuCard>
                    }
                >
                    {(item) => (
                        <Button
                            {...item}
                            color="accent"
                            shape="square"
                            size="large"
                            variant="muted"
                            className={dialogTriggerButton}
                        >
                            <UserCircle />
                        </Button>
                    )}
                </Menu>
            </Row>
            <PageLoading />
        </header>
    );
};
