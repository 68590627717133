import type { Ability } from 'types';

export const hasAbility = (abilitySets: Record<string, unknown>, ability: Ability) => {
    const abilities = new Set(Object.values(abilitySets).flat() as string[]);

    return (
        !abilities.has(`!${ability}`) &&
        (abilities.has(ability) || abilities.has(ability.replace(/\..+/, '.*')) || abilities.has('*.*'))
    );
};
